.project-card {
  cursor: pointer;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 3px;
  border: 1px solid #C4C4C4;
  
  .project-card-header {
    width: 100%;
    display: flex;
    padding-top: 16px;
  
    .project-card-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; 
      margin-left: 24px ;
      flex: 1;
      color: #21201F;
      font-family: 'RocheSans-Medium';
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
    .project-card-star {
      margin-right: 10px ;
    }
    .project-card-three-dots {
      margin-right: 18px ;
      cursor: pointer;
    }
  }
  .project-card-content {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 0px 0px 3px 3px;
    background: #FFF;

    .project-card-row {
      display: flex;
      width: 100%;
      align-items: baseline;
    }

    .project-card-text {
      flex: 1;
      color: #21201F;
      font-family: 'RocheSans-Medium';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
    }

    .project-card-label {
      color: #544F4F;
      font-family: 'RocheSans-Medium';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
    }

    .project-card-status {
      display: flex;
      width: 66px;
      padding: 4px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 24px;
      background: #C7E1C4;
      color: #21201F;
      font-family: 'RocheSans-Medium';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; 
      letter-spacing: 0.3px;
    }


    .project-card-footer {
      flex: 1;
      display: flex;
      align-items: center;
      color: #544F4F;
      font-family: 'RocheSans-Medium';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
    }
  }
}
