$navbarWidth: 220px;
$minimizedNavbarWidth: 65px;
$mainHeader: 50px;
$smallHeader: 26px;
$headersHeight: $mainHeader + $smallHeader;
$mainBlue: #0066CC;
$secondary: #686D71;
$error: #E76F51;
$warning: #F4A261;
$success:  #2A9D8F;
$paragraphGray: #4F4F4F;
$formTitle: #757575;
$label: #878787;
$lightBlue: #B0E2FA;
$green: #36b17e;