.security-details,
.product-details,
.ownership-details,
.applicability-details {
  margin-top: 30px;
}

.answer {
  color: #21201f;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.review-state {
  .MuiOutlinedInput-input.Mui-disabled {
    color: #21201f;
    -webkit-text-fill-color: #21201f;
  }
}

.document-management-system-details {
  padding-top: 50px;
  border-top: 1px solid #c2bab5;
  margin-top: 50px;
}

.basic-details {
  text-align: left;
}

.transfer-output {
  .loader-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: #ffffff7d;
  }

  .loader-container {
    text-align: center;
    padding: 20px;
    position: absolute;
    top: calc(50% - 80px);
    left: calc(50% - 80px);
    z-index: 2;
  }
}

.ia-radio {
  .MuiFormGroup-row {
    .option-0 {
      margin-right: 100px;
    }
  }
}

.incorrect-doc-number {
  text-align: left;
  font-size: 16px;
  color: #000;
  letter-spacing: 0.2px;
  font-weight: 400;
  .contact {
    color: #0b41cd;
    text-decoration: underline;
    cursor: pointer;
  }
}
