@import '../../assets/scss/variables';

.ia-search-input {
  display: flex;
  align-items: center;
  svg {
    width: 24px;
  }
  .ia-input {
    // margin-left: 10px;
    width: 240px;
  }
}
