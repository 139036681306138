.date-picker {
    .MuiTextField-root {
        width: 100%;
    }
    .MuiOutlinedInput-notchedOutline {
        border-color: #c2bab5 !important;
        border-width: 1px;
        border-radius: 2px;
    }
    input {
        font-size: 18px;
        color: #706b69;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        font-family: 'RocheSans-Light';
        padding-top: 12px;
        padding-bottom: 12px;
        height: 24px;
    }
    input:disabled {
        background-color: #FBFBFA;
    }

    label {
        color: #706b69;
        font-family: 'RocheSans-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
    }
}