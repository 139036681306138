@import '../../assets/scss/variables';

.MuiPaper-root {
  border-radius: 2px !important;
}

.dialog-header {
  padding: 24px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .dialog-title {
    color:  #21201F;
    font-family: "RocheSans-Regular";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    display: flex;
  }
  .close-icon {
    cursor: pointer; 
  }
  .confirmation-text {
    color: #21201F;
    font-family: 'RocheSans-Medium';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  .title-icon {
    margin-right: 5px;
  }
}

.MuiDialogContent-root {
  font-size: 16px;
  font-weight: 700;
  color: #4f4f4f;
  // text-align: center;
  padding: 0px 24px !important;
  .message {
    margin: 0 0 20px;

  }
  .confirmation {
    font-weight: 400;
    font-size: 14px;
  }
}

.MuiDialogActions-root {
  padding: 24px !important;
}