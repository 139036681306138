@import '../../assets/scss/variables';

.wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    position: relative;
  }
  
  .main {
    display: flex;
    align-items: center;
    align-content: center;
    z-index: 999;
    width: 100%;
    background: linear-gradient(127deg, #FFF 10.3%, #FFF7F5 51.57%, #BDE3FF 143.54%);
  }
  
  .login-form-container {
    background-color: white;
    width: 40%;
    height: 100vh;

    .roche-icon {
      margin-top: 5%;
      margin-left: 7.5%;
      position: absolute;
    }

    .appLogo-login {
      .logo-icon {
        width: 33px;
      }
    }

    .login-block {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
      margin-left: 21%;
      margin-right: 21%;

      .login-title {
        color: #21201F;
        font-family: 'RocheSans-Light';
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; 
      }

      .login-description {
        color: #000;
        font-family: 'RocheSans-Light';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
        .learn-more {
          color: $mainBlue;
          font-weight: 700;
          text-decoration: none;
          svg {
            vertical-align: middle;
            font-size: 16px;
            margin-bottom: 2px;
          }
        }
      }

      .login-btn {
        margin-top: 20px;
        color: #FFF;
        background: $mainBlue;
        padding: 12px 24px;
        text-decoration: none;
        text-align: center;
        font-family: 'RocheSans-Regular';
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
      }
    }
  }

  .login-carousel-container {
    width: 60%;
    display: flex;

    .img-container {
      display: flex;
      justify-content: center;
    }

    .carousel-dot {
      width: 16px;
      height: 10px;
      border-radius: 10px;
      background: #D9D9D9;
      margin-right: 5px;
      cursor: pointer;
    }

    .carousel-dot-active {
      width: 32px;
      height: 10px;
      border-radius: 10px;
      margin-right: 5px;
      background: $mainBlue;
    }
    
    .img-container-inner {
      text-align: center;
      margin-left: 19%;
      margin-right: 19%;
    }

    .onboarding-text {
      color: #000;
      font-family: 'RocheSans-Light';
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
      margin-top: 51px;
    }

    .alice-carousel__dots {
      margin-top: 10px
    }
  }
  
  .logo {
    justify-self: center !important;
    width: 252px !important;
    height: 250px !important;
  }
  
  .card {
    max-width: 642px;
    max-height: 400px;
    min-width: 642px;
    margin-right: 25px;
    justify-self: center;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12) !important;
  }
  
  .login {
    max-width: 641px;
    min-height: 130px;
    margin: 30px;
    display: grid;
    grid-row-gap: 15px;
    justify-self: center;

    a {
      text-decoration: none;
    }
  }
  
  .welcome {
    color: #6d6d6d;
    font-size: 15px;
    font-weight: 500;
  }
  
  .google-button {
    align-items: center;
    text-align: center;
  }
  
  .error {
    color: red;
    margin-top: 15px;
  }
  
  
  
  /*TODO animation with nib*/
  
  .brand-boat-container {
    position: absolute;
    bottom: 8px;
    z-index: 6;
    animation: boat_trip 200s linear infinite !important;
    -webkit-animation: boat_trip 200s linear infinite !important;
    margin-bottom: 0.5em;
  }
  
  .brand-boat {
    animation: boat_lost 2s ease-in-out infinite;
  }
  
  .use-password {
    user-select: none;
    cursor: pointer;
    color: #6d6d6d;
    width: max-content;
    justify-self: flex-end;
  }
  
  .credentials-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .login-input, .password-input {
    width: 100%;
    border-bottom: 2px solid #ccc;
    height: 45px;
    color: #6d6d6d;
  }
  
  .error-input {
    border-bottom: 2px solid red;
  }
  
  .error-input::placeholder {
    color: red;
  }
  
  .login-button {
    width: 100%;
    height: 45px;
    margin-top: 15px;
    background: $mainBlue;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    letter-spacing: 1px;
  }
  
  .error-messsage {
    align-self: flex-start;
    color: red;
    font-size: 10px;
    margin-top: 10px;
  }
  
  .loginSSOorGoogle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 1em;
    .loginSSOContent, button {
      flex: 1 1 0px
    }
  }
  
  .loginSSO {
    margin-left: 10px;
    padding: 10px 10px 10px 0px;
    font-weight: 500;
  }
  
  .loginSSOContent:hover{
        cursor: pointer;
        opacity: 0.8;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.54);
  }
  
  
  .loginSSOContent{
    display: flex;
    align-items: center;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    padding: 5px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-family: 'RocheSans-Regular';
    height: 43px;
  }
  
  .lo{
    width: 130px;
    height: 15px;
    background: #0066cc url(../../assets/svg/logo-roche.svg) no-repeat 1px;
  }
  
  @keyframes boat_trip {
    0% {
      margin-left: -2%;
    }
  
    10% {
      margin-left: 5%;
      transform: translate3d(0, 0, 20%);
    }
  
    100% {
      margin-left: 120%;
    }
  }
  
  @keyframes boat_lost {
    0% {
      margin-left: 40%;
      transform: rotateY(0deg);
    }
  
    45% {
      margin-left: 60%;
      transform: rotateY(0deg);
    }
  
    50% {
      margin-left: 60%;
      transform: rotateY(180deg);
    }
  
    95% {
      margin-left: 40%;
      transform: rotateY(180deg);
    }
  
    100% {
      margin-left: 40%;
      transform: rotateY(0deg);
    }
  }
  
  @keyframes boat_rock {
    from {
      transform: rotate(20deg);
    }
  
    to {
      transform: rotate(30deg);
    }
  }
  