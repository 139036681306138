.template-details {
    .MuiDialogContent-root {
        text-align: unset;
    }
    .divider {
        border: 1px solid #C2BAB5 !important;
        margin: 48px 0px;
    }
    .template-three-dots {
        padding: 10px;
        cursor: pointer;
        margin-left: 10px;
    }
    .options-menu {
        .options-list {
          z-index: 999;
          a {
            text-decoration: none !important;
            color: #21201f !important;
          }
      
          .menu-item {
            color: #21201f !important;
            font-size: 18px;
            font-family: 'RocheSans-Light';
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
          }
      
          .MuiPopover-paper {
            width: 200px;
          }
        }
      }
}
