@import '../../assets/scss/variables';

.working-document-details {    
    .document-data {
        .select {
            width: 100% !important;
        }
        .input-with-label {
            width: 100% !important;
        }
    }
    .template-container {
        margin-top: 32px;
        .page-content {
            margin-left: unset
        }
    } 
    .MuiTableCell-head:last-child:nth-child(odd) {
        background-color: #EAE8E5;
    }
    .MuiTableCell-head:last-child:nth-child(even) {
        background-color: #F5F5F2;
    }
    .title-container {
        .ia-input {
            width: 403px;
        }
    }   
    .components-title {
        margin-bottom: 31px;
    }
    .components-block {
        max-height: 415px;
        overflow: auto;
        width: 95%;        
        .section-title {
            color: #21201F;
            font-family: 'RocheSans-Light';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 17px;
            margin-top: 17px;
            margin-left: 28px;
            width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            span {
                margin-left: 12px;
                vertical-align: middle;
            }
        }
        .tick-icon {
            width: 20px;
            height: 20px;
            margin-top: 5px;
        }
    }
    .component-preview {
        border-radius: 2px;
        border: 1px solid #C2BAB5;
        background: #F5F5F2;
        width: 80%;
        height: 345px;
        padding: 33px;
    }
    .components-list {
        width: 40%;
    }
    .components-container {
        display: flex;
    }
    .component-xml-container {
        width: 50%;
    }
    .component-xml {
        background-color: white;
        padding: 10px;
    }
    .component-xml:nth-child(1) {
        margin-right: 24px;
    }
    .component-title {
        color: #21201F;
        font-family: 'RocheSans-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 17px;
        width: 90%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        span {
            margin-left: 12px;
            vertical-align: middle;
        }
    }
    .component-preview-blocks {
        display: flex;
        align-items: center;        
        justify-content: center;
    }
    .component-preview-description {
        display: block;
        color: #21201F;
        font-family: 'RocheSans-Light';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        height: 48px;
        margin-bottom: 12px;
        margin-top: 8px;
    }
    .component-option {
        display: flex;
        align-items: center;
        color: #000;
        font-family: 'RocheSans-Light';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-top: 12px;
        height: 28px;
    }
    .review-table-title {
        color: #706B69;
        font-family: "RocheSans-Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        margin-top: 24px;
        margin-bottom: 16px;
    }
    .divider {
        border: 1px solid #C2BAB5 !important;
        margin: 48px 0px;
    }
    .select-options-container {
        margin-top: 16px;
        .select-options-text {
            color: #21201F;
            font-family: 'RocheSans-Regular';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.2px;
        } 
        .select-text {
            vertical-align: middle;
            color: #21201F;
            font-family: 'RocheSans-Light';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
        }
    }
    .tab-panel {
        margin-top: 32px;
    }
    
    .title-back-icon {
        margin-top: 5px;
    }
  
    .output-table {
        margin-bottom: 48px;
    }
    .dialog-title {
        color: #21201f !important;
        font-family: "RocheSans-Medium" !important;
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 28px !important;
    }
    .MuiDialogContent-root {
        text-align: unset;
    }
    .transfer-section {
        margin-bottom: 50px;
    }
    .table-container {
        min-height: unset;
    }
    .document-three-dots {
        padding: 10px;
        cursor: pointer;
        margin-left: 10px;
    }

    .options-menu {
        .options-list {
          z-index: 999;
          a {
            text-decoration: none !important;
            color: #21201f !important;
          }
      
          .menu-item {
            color: #21201f !important;
            font-size: 18px;
            font-family: 'RocheSans-Light';
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
          }
      
          .MuiPopover-paper {
            width: 200px;
          }
        }
      }

      .no-results {
        .title {
            width: 19%;
            margin: 0 auto;
        }
      }
}

