.table-header {
  .table-header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    .left {
      display: flex;
    }
    .right {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .ia-search-input {
      .ia-input {
        width: 403px;
      }
    }
  }
  .table-title {
    width: max-content;
    color: #21201F;
    font-family: 'RocheSans-Regular';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}
