@import '../../assets/scss/variables';

.ia-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  padding-top: 34px;
  padding-bottom: 34px;
  .ia-pagination-name {
    color: #7b7b7b;
    font-weight: 700;
    font-size: 14px;
    margin-right: 10px;
  }
  .ia-pagination-size {
    display: flex;
    justify-content: flex-start;
    margin-right: 25px;
    cursor: pointer;

    .ia-pagination-count {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        font-weight: 700;
        font-size: 14px;
        min-width: 50px;
        text-align: center;
        margin-right: 5px;
      }
    }
  }
  .ia-pagination-pages {
    display: flex;
    justify-content: flex-start;
    .ia-pagination-arrows {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > svg {
        width: 14px;
        height: auto;
        cursor: pointer;
      }
      > span {
        font-weight: 700;
        font-size: 14px;
        min-width: 50px;
        text-align: center;
        margin-right: 5px;
      }
      .total-count {
        font-family: 'RocheSans-Light';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
      }
    }
  }
  .ia-page-menu {
    .MuiMenu-paper {
      min-width: 100px;
      ul {
        li {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .pagination-info {
    .shown-count {
      padding: 12px;
    }  
    .total-count {
      padding-right: 12px;
    } 
  }
  .ia-input {
    width: 48px;
    height: 36px;
    padding-left: 5px;
    input {
      font-size: 16px !important;
    }
  }  
  .arrow-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      width: 14px;
      height: auto;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
