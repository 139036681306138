.empty-state {
  margin-top: 100px;
  text-align: center;

  .icon {
    width: 164px;
    height: 164px;
  }

  .title {
    color: #21201F;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'RocheSans-Regular';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    min-height: 0px;
    margin-top: 33px;
    margin-bottom: 6px;
  }

  .sub-title {
    color: #706B69;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'RocheSans-Light';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    letter-spacing: 0.2px;
    margin-top: 6px;
  }

  .action-link {
    display: inline !important;
  }

  .main-action-btn {
    margin-top: 55px;
    margin-bottom: 180px;
    display: inline-flex !important;
  }
}