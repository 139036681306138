@import '../../assets/scss/variables';
/* Left sidebar menu items */

.left-menu {
  transition: all 300ms ease-in-out;
  height: 100%;
  > nav {
    height: 100%;
  }

  .menu__item {
    cursor: pointer;
    color: #878787;
    height: 45px;
    overflow: hidden;
    padding: 0 20px;
    width: 206px;
    transition: all 350ms ease-in-out;

    .MuiTypography-caption {
      font-size: 16px;
      font-family: 'RocheSans-Regular';
      line-height: 14px;
      letter-spacing: unset;
    }

    &:not(.menu__item--active) {
      &:hover {
        color: $mainBlue;
        background-color: white;
        height: 45px;
        overflow: hidden;
        path {
          color: $mainBlue;
          fill: $mainBlue;
        }
      }
    }
    &.menu__item--active {
      path {
        color: $mainBlue;
        fill: $mainBlue;
      }

      span {
        color: $mainBlue;
      }
    }
  }

  .divider {
    border: 1px solid #C2BAB5 !important;
    margin: 24px;
  }
}

.arrow {
  position: absolute;
  bottom: 30px;
  width: 50px;
  .arrows {
    svg {
      fill: #878787;
    }
    svg:hover {
      fill: $mainBlue;
    }
  }
}

.user-guide-icon {
  &:hover {
    color: $green !important;
    path {
      color: $green !important;
      fill: $green !important;
    }
  }
}
