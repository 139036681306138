.ia-tabs {
  border-bottom: 1px solid #c2bab5;
  .MuiTab-root {
    padding: 12px 0;
    margin-right: 16px;
    // &:first-child {
    //   padding: 12px 0;
    // }
    // &:not(:first-child) {
    //   padding: 12px 0 12px 16px;
    // }
    text-transform: none;
    span.label {
      margin-right: 12px;
    }
    span.count {
      border-radius: 24px;
      background: #dbd6d1;
      padding: 0px 6px;
      color: #21201f;
    }
    &.Mui-selected {
      color: #022366;
    }
  }

  .MuiTabs-indicator {
    background-color: #022366;
  }
}
