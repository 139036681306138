.manage-columns {
    .column-search {
        .input-with-label {
            width: 100%;
        }
        .ia-input {
            width: 100%;
        }
    }
    .description {
        font-size: 16px;
        color: #878787;        
        font-weight: 400;
        text-align: left;
        margin: 0px 0px 24px 0px;
    }
}
