@import '../../assets/scss/variables';

.app {
  display: flex;
  padding-left: $navbarWidth;
  background: #f5f5f2;
  overflow: auto;
  height: calc(100vh - 54px);
  transition: all 350ms ease-in-out;
  > div:last-child {
    width: 100%;
    background: #ffffff;
    margin: 32px;
    height: fit-content;
    overflow-x: hidden;
    min-height: calc(100vh - 124px);

  }
}

.app--minimized {
  padding-left: $minimizedNavbarWidth;
}

.app--withoutSubNav {
  height: calc(100vh - 54px);
}

.app--guideOpen {
  padding-left: 0;
}

.app__sidebar {
  background-color: white;
  width: $navbarWidth;
  box-shadow: 1px 0px 2px 0px #dbd6d1;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: space-between;
  position: fixed;
  left: 0;
  height: calc(100vh - 54px);
  top: 54px;
  z-index: 2;
  transition: all 300ms ease-in-out;
}

.app__sidebar {
  min-height: calc(100vh - 50px);
  top: 54px;
}

.appLogo {
  margin: 12px 10px;
  .logo-icon {
    width: 18px;
    &.big {
      width: 100px;
    }
  }
  .divider {
    width: 30px;
    height: 1px;
    background: #C2BAB5;
    margin-top: 12px;
  }
  
}

.app--minimized .app__sidebar {
  width: $minimizedNavbarWidth;
  transition: all 300ms ease-in-out;
}

.app__content {
  flex: 1;
  min-height: calc(100vh - 76px);
}

.app--withoutSubNav .app__content {
  min-height: calc(100vh - 50px);
}

.app__sidebar .bottom-content {
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.mdb-datatable div {
  &.mdb-datatable-length label,
  &.mdb-datatable-filter label {
    font-weight: 500 !important;
  }
}

.title {
  min-height: 48px;
  padding: 0 5px;
  align-items: center;
}

.dt-table-header-tr > div {
  font-size: 11px;
  font-weight: bold;
}

#background-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  z-index: 2;
  opacity: 0.4;
  display: none;
}