@import '../../assets/scss/variables';

.ia-autocomplete {
  position: relative;
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiInputBase-root {
        border-radius: 2px;
        padding: 0px 12px 0px 16px;
        overflow: hidden;
        .MuiAutocomplete-input {
          font-family: 'RocheSans-Light';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.2px;
        }
        input::placeholder {
          font-size: 18px;
          font-family: 'RocheSans-Light';
        }
        fieldset {
          border-width: 1px;
          border-color: #C2BAB5;
        }
        &:hover {
          fieldset {
            border-color: #C2BAB5;
          }
        }
      }
    }
  }
  .MuiAutocomplete-popper {
    .MuiAutocomplete-option {
      text-align: center;
    }
  }

  .divider {
    position: absolute;
    width: 4px;
    height: 100%;
    right: 30px;
    bottom: 0;
    background-color: #0065cb;
  }

  label {
    color: #706B69;
    font-family: 'RocheSans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    margin-bottom: 44px;            
  }
  .MuiChip-root {
    background-color: white;
    border: 1px solid #AEC0EE;
    display: flex;
    padding: 4px 8px 4px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  }
  .MuiChip-label {
    color: $mainBlue;
    font-family: 'RocheSans-Regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
  }

  .MuiAvatar-colorDefault {
    background-color: #D7E0F7;
    color: #0B41CD !important;
    text-align: center;
    font-family: 'RocheSans-Medium';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0.5px;
  }
}
