@import '../../assets/scss/variables';

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.drawerHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 5px;
  background-color: #0066cc;
  color: #ffffff;
  align-items: center;
  .sidebarTitle {
    font-size: 24px;
    color: #fff;
    padding-left: 20px;
  }
  .close-icon {
    margin-right: 10px;
    cursor: pointer;
    svg {
      font-size: 15px;
    }
  }
}

.drawer {
  flex-shrink: 0;
  > .MuiPaper-root {
    width: 300px;
    overflow: hidden;
    margin-top: $mainHeader + 3px;
  }
}
.app--guideOpen {
  .drawer {
    width: 300px;
  }
}
.drawerPaper {
  width: 300px;
  background-color: #f4f5f7;
}
.drawerRoot {
  width: 300px;
  position: fixed;
}
.fullList {
  width: auto;
}
.toolbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.root {
  width: 100%;
  max-width: 360;
}

.helpButton {
  position: fixed;
  bottom: 0;
  margin: 40;
}
.doneCircleIcon {
  fill: #36b17e;
}
.notDoneCircleIcon {
  fill: #dfe1e6;
}
.listItem {
  border-top: 6px solid #36b17e;
  background-color: rgb(255, 255, 255);
  margin: 10px;
  border-radius: 4px;
  line-height: 20px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px;
  transition: height 2000ms ease 0s;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: rgb(255, 255, 255);
  }
  .MuiMobileStepper-root {
    display: flex;
    padding: 8px;
    background: #fafafa;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}
.collapse {
  width: 100%;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
}

.listWrapper {
  overflow-y: auto;
  padding: 0 18px;
  width: 100%;
  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .MuiListItem-gutters {
    padding-left: 16px;
    padding-right: 16px;
  }
  .MuiListItem-root {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
  }
  .MuiListItemIcon-root {
    color: rgba(0, 0, 0, 0.54);
    display: inline-flex;
    min-width: 56px;
    flex-shrink: 0;
  }
  .MuiListItemText-root {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    &.check-icon {
      fill: #dfe1e6;
    }
  }
  .MuiTypography-body1 {
    font-size: 1rem;
    font-family: 'RocheSans-Regular';
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    width: 126px;
  }
  .MuiTypography-body2 {
    font-size: 0.875rem;
    font-family: 'RocheSans-Regular';
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    max-width: 211px;
  }
  .MuiTypography-colorTextSecondary {
    color: rgba(0, 0, 0, 0.54);
  }
}
.closeBtn {
  margin-right: unset;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.search-tutorial {
  margin-top: 15px;
  svg {
    margin-left: 5px;
  }
}
.help-center-title {
  display: flex;
  padding: 8px 0px;
  margin-left: 30px;
  margin-top: 20px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: #544F4F;
  font-family: 'RocheSans-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;

  svg {
    font-size: 18px;
    color: #21201F;
    cursor: pointer;
  }
}
