@import '../../assets/scss/variables';

html,
body {
  font-family: 'RocheSans-Regular';
  font-weight: 500;

  ::-webkit-scrollbar {
    width: 5px;
    height: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background: #0066cc;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
.form-content {
  padding: 14px 32px;
}
.loader-container {
  text-align: center;
  padding: 20px;
  &.with-bg {
    text-align: center;
    padding: 20px;
    position: absolute;
    top: calc(50% - 80px);
    left: calc(50% - 80px);
    z-index: 2;
  }
}
.loader-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff7d;
}
.disable-events {
  pointer-events: none;
}

.watch-tutorial {
  cursor: pointer;
  font-size: 10px;
  font-weight: 800;
  text-decoration: underline;
  margin-right: 10px;
  text-transform: uppercase;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px;
  .MuiButton-root.ia-btn.main-action-btn {
    margin-right: 0;
  }
}
.action-link {
  color: #0066cc;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.page-content {
  position: relative;
  margin: 30px;
  .manage-columns {
    position: absolute;
    top: 10px;
    right: 0;
  }
}

.form-button-container {
  display: flex;
  margin: 48px 0;
}

.action-title {
  color: #21201f;
  font-family: 'RocheSans-Medium';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.form-field, .description-container {
  margin-top: 24px;
  .input-with-label {
    width: 100%;
  }
  .MuiOutlinedInput-root {
    margin-top: 4px;
  }
  .label {
    color: #706b69;
    font-family: 'RocheSans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
  .form-button-container {
    margin: 0;
  }
}

// .select {
  // width: 403px;
  // margin-top: 24px;
  // .MuiAutocomplete-root {
  //     margin-top: 4px
  // }
// }

.action-title {
  color: #21201f;
  font-family: 'RocheSans-Medium';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.action-subtitle {
  color: #21201f;
  font-family: 'RocheSans-Light';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.description-container {
  margin-top: 24px;
}

.input {
  // margin-top: 14px;
  label {
    color: #706b69;
    font-family: 'RocheSans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
  .Mui-disabled {
    background: #fbfbfa;
  }
}

.alice-carousel {
  height: inherit;
}

.stepper-container {
  margin: 48px 0;
  .stepper {
      display: flex;
      justify-content: flex-start;
      align-items: left;            
  }
  .MuiStepLabel-root {
      -webkit-align-items: flex-start;
      -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
      align-items: flex-start;
  }
  .MuiStepIcon-text, .MuiStepLabel-label {
      color: #21201F;
      font-size: 16px;
      font-family: 'RocheSans-Regular';
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;  
  }
  .working-document-stepper {
    .MuiStepConnector-root {
      left: calc(-40% - 75px);
    }
  }
  .project-stepper {
    .MuiStepConnector-root {
      left: calc(-40% - 70px);
    }
  }  
  .active {        
      svg {
          z-index: 2; 
          width: 32px;
          height: 32px;               
          border-radius: 42px;
          border: 2px solid $mainBlue;
          padding: 5px;
          margin-top: -10px;
          fill: $mainBlue;
      }
      .MuiStepLabel-label {
          margin-top: 12px;               
      }           
  }
  .non-active {
      .MuiStepIcon-text {
          fill: #544F4F;
      }
      svg {
          z-index: 2; 
          width: 32px;
          height: 32px;
          fill: #DBD6D1;                
      }                    
  }
  .non-active:hover {
      svg {
          border-radius: 42px;
          border: 2px solid #DBD6D1;
          padding: 5px;
          margin-top: -10px;
          transition: 100ms;  
      }
      .MuiStepLabel-label {
          margin-top: 12px;               
      }
  }
  .completed {
      svg {
          z-index: 2; 
          width: 32px;
          height: 32px;               
          border-radius: 42px;
          fill: $mainBlue;
      }
  }
  .completed:hover {
      svg {
          border-radius: 42px;
          border: 2px solid $mainBlue;
          padding: 5px;
          margin-top: -10px;
          transition: 100ms;  
      }
      .MuiStepLabel-label {
          margin-top: 12px;               
      }
  }
  .MuiStepLabel-alternativeLabel {
      white-space: nowrap;
      text-align: left;
  }        
} 

.details {
  margin-bottom: 48px;
  display: flex;
  gap: 32px;
  .detail-text {
    color: #878787;
    font-family: "RocheSans-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
}

.title-buttons {
  display: flex;
  .favorite-icon {
    padding: 10px;
    cursor: pointer;
    margin-right: 10px;
  }
  .status {
      color: #21201F;
      font-family: "RocheSans-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.3px;
      border-radius: 100px;
      background: #FFE082;
      display: flex;
      width: 100px;
      height: 36px;
      padding: 4px 10px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
  }
  .status-draft {
      background: #FFE082;
  }
  .status-in-review {
      background: #C7E2FC;
  }
  .status-released {
      background: #C7E1C4;
  }
  .status-archived {
      background: #EAE8E5;
  }
  button {
      margin-left: 24px;
  }
}
.title-back-icon {
  margin-top: 5px;
}
.add-more-container {
  .add-more {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 8px;
    }
    span {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      color: #0b41cd;
    }
  }
  display: flex;
  margin-top: 24px;
  height: 100%;
  position: relative;
  z-index: 3;
}

.Mui-error {
  .MuiOutlinedInput-notchedOutline {
    border-color: #C03 !important
  }
}

.MuiFormHelperText-root.Mui-error, .err-message {
  color: #C03 !important;
  font-family: 'RocheSans-Regular';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-top: 8px;
  margin-left: 0px;
}

.project-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 400px);
}

.divider-dotted {
  margin: 30px 0px;
  border: none;
  height: 2px;
  background: repeating-linear-gradient(90deg,#dedbd8, #dedbd8, 6px,transparent 6px,transparent 12px);
}
.product-group-title, .country-group-title {
  color:  #21201F;
  font-family: 'RocheSans-Medium';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.56;
  margin-right: 24px;
  letter-spacing: normal;
}
.review-value {
  margin-top: 4px;
  font-family: 'RocheSans-Regular';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  text-align: left;
  color: #21201f;
  word-break: break-word;
}

.project-card-avatar {
  .MuiAvatar-colorDefault {
    background-color: #D7E0F7;
    color: #0B41CD;
    text-align: center;
    font-family: 'RocheSans-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
  }
}

.details-members-container {
  display: flex;
  justify-content: space-between;
  width: 97%;
}

.members {
  .input-with-label {
      width: 100%;
  }
  .ia-input {
      width: 100%;
  }
  .member-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      margin-top: 24px;
  }
  .member-name {
      font-family: 'RocheSans-Regular';
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.3px;
      text-align: left;
      color: #21201f;
      margin-left: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
  }

  .creator-avatar {
    align-items: center; 
    display: flex;
  }

  .role-name-dialog {
    font-family: 'RocheSans-Light';
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: left;
    color: #21201f;
  }
}

.members-container {
  margin-top: 12px;
  .MuiChip-root {
    background-color: white;
    border: 1px solid $mainBlue;
    display: flex;
    padding: 4px 8px 4px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    height: 52px;
  }

  .MuiChip-label {
    color: $mainBlue;
    font-family: 'RocheSans-Regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
  }
  
  .MuiAvatar-colorDefault {
    background-color: #D7E0F7;
    color: #0B41CD !important;
    text-align: center;
    font-family: 'RocheSans-Medium';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0.5px;
  }

  .member-name {
      font-family: 'RocheSans-Regular';
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.3px;
      text-align: left;
      color: #0b41cd;
  }

  .role-name {
      font-family: 'RocheSans-Light';
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.2px;
      text-align: left;
      color: #0b41cd;
  }
}

.MuiAccordion-root {
  box-shadow: unset !important;
  .Mui-disabled {
    background-color: white;
    opacity: 1 !important;
  }
}

.MuiAccordionSummary-root {
  padding: unset !important;
}

.MuiAccordionDetails-root {
  padding: unset !important;
}

.read-more {
  font-family: "RocheSans-Bold";
  color: $mainBlue;
  cursor: pointer;
}

.MuiAccordionSummary-expandIconWrapper {
  margin-bottom: 50px;
}

.disabled {
  pointer-events: none;
}