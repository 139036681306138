@import '../../assets/scss/variables';

.dashboard-add-button-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: fixed;
    align-self: flex-end;
    height: 56px;
    z-index: 3;
    bottom: 15px;
    right: 45px;
    .dashboard-add-button-outer {
        display: flex;
        justify-content: flex-end;       
    }
    .dashboard-add-button {
        display: flex;
        align-self: flex-end;
        margin-top: 10px;
        svg {
            width: 56px;
            height: 56px;
            cursor: pointer;
        }
    }
    .dashboard-add-button-inner {
        display: flex;
        flex-direction: column;
        z-index: 3;
    }
    .create-button-container {
        display: flex;
        flex-direction: column;
        .create-button {
            margin-top: 20px;
            border-radius: 100px;
            color: $mainBlue;
            background-color: #FFF;
            border-color: #FFF;
            border-width: 0;
            padding: 10px 27px;
            font-family: 'RocheSans-Regular';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.2px;
            cursor: pointer;
        }
    }
}
