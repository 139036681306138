.project {
  .form-content {
    .button-container {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
  .members {
    margin-top: 48px;
  }
  .divider {
    border: 1px solid #c2bab5 !important;
    margin: 32px 0px;
  }  
}
