@import '../../../assets/scss/variables';

.applied-filters {
  display: flex;
  margin: 0 30px;
  margin-bottom: 24px;

  .applied-filter-item {
    background: #E0F0FF;
    border-radius: 21px;
    display: flex;
    align-items: center;
    color: #0066CC;
    padding: 6px 12px 6px 16px;
    min-width: 55px;
    height: 18px;
    cursor: pointer;
    margin: 4px 10px 2px 0;
    border: none;
    .filter-text {
      font-size: 16px;
      font-weight: 700;
      margin-right: 8px;
      margin-left: 5px;
    }
    .cross svg {
      width: 11px;
      height: 11px;
    }
  }
}
