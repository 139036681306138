
@font-face {
  font-family: 'RocheSans-Bold';
  src: url('./assets/fonts/RocheSans-Bold.ttf');
}
@font-face {
  font-family: 'RocheSans-Light';
  src: url('./assets/fonts/RocheSansLight-Light.ttf');
}
@font-face {
  font-family: 'RocheSans-Regular';
  src: url('./assets/fonts/RocheSans-Regular.ttf');
}
@font-face {
  font-family: 'RocheSans-Medium';
  src: url('./assets/fonts/RocheSansMedium-Medium.ttf');
}

body {
  margin: 0;
  font-family: 'RocheSans-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #4f4f4f;
  font-weight: 400;
  overflow: hidden;
}
input::placeholder {
  font-size: 14px;
  opacity: 0.5;
}

.MuiMenu-list ul {
  outline-color: #fff !important
}
