.review-container {
  .review-action-title {
    display: flex;
    position: relative;
    justify-content: space-between;
  }
  .action-title-text {
    color:  #21201F;
    font-family: 'RocheSans-Medium';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-right: 24px;
  }
  .action-title-edit-icon {
    margin-top: 4px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  .action-title-edit-text {
    margin-top: 3px;
    margin-left: 8px;
    color: #06C;
    font-family: 'RocheSans-Regular';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    cursor: pointer;
  }
  
  .divider {
    margin: 16px 0px !important;
  }
}
