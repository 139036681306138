.data-preview-list {
  margin-top: 25px;
  display: flex;
  .data-preview-card {
    max-width: 350px;
    border: 1px solid #d2d3d4;
    cursor: pointer;
    margin-right: 10px;
    .preview-image-placeholder {
      padding: 30px 15px;
      border-bottom: 1px solid #d2d3d4;
      background: #f6f6f7;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .title-container {
      margin: 30px 15px;
      .title {
        min-height: unset;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
}
