.iframe_class {
  border-width: unset;
  border-style: unset;
}

.warning-message {
  color: #21201F;
  font-family: 'RocheSans-Light';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
