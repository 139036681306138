@import '../../assets/scss/variables';

.header {
  padding: 2px 0;
  display: flex;
  height: $mainHeader;
  width: 100%;
  align-items: center;
  z-index: 4;
  background-color: #fff;
  .roche-icon {
    margin-left: 16px;
  }
  .divider {
    width: 1px;
    height: 16px;
    background-color: #c2bab5;
    margin: 0 24px;
  }
  .ia-title {   
    a {
      color: $mainBlue;
      font-family: 'RocheSans-Regular';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
      text-decoration: none;
    }
  }
  .profile-popup {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .logout-modal {
    z-index: 999;

    a {
      text-decoration: none !important;
      color: $secondary !important;
    }

    .menu-item {
      color: $secondary !important;
    }

    .MuiPopover-paper {
      width: 270px;
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: #d7e0f7;
    justify-content: center;
    border-radius: 50%;
    .user-name {
      color: #0b41cd;
      font-family: 'RocheSans-Regular';
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
    }

    .user-photo {
      width: 30px;
      margin: 10px;
      height: 30px;
      border-radius: 50%;
    }
  }

  .logout-modal-btn {
    margin-right: 16px;
  }

  .environment {
    border-radius: 100px;
    background: #ff7d29;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'RocheSans-Regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 24px;
    height: 36px;
  }

  .doc-title {
    a {
      color: $mainBlue;
      font-family: 'RocheSans-Regular';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
      cursor: pointer;
      text-decoration: none;
    }
    svg {
      vertical-align: text-top;
      font-size: 18px;
    }
  }
}
