.MuiPopper-root {
  z-index: 3;
}
.filter-btn {
  background-color: #0066cc;
  border-radius: 5px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  padding: 12px 10px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  svg {
    margin-right: 8px;
  }
}

.filter-select {
  max-height: 36px;

  &__indicator {
    padding-right: 4px !important;
    padding-left: 4px !important;

    svg {
      fill: #4f4f4f;
      width: 17px;
      height: 17px;
      opacity: 1;
    }
  }
  &__value-container {
    div.filter-select__input {
      input {
        min-height: auto;
      }
    }
  }
}


// .filter-content {
//   padding: 12px 10px;
//   background-color: #fff;
//   border-radius: 14px;
//   box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);

.filter-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #878787;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 7px;
  border-bottom: 3px solid #0066cc;

  .cross {
    display: flex;
    cursor: pointer;
    svg {
      width: 11px;
      height: 11px;
    }
  }
}

.filters-body {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filter-by {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-right: 16px;
  }
  .field-item {
    margin-right: 15px;
    label {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 700;
      display: block;
      margin-bottom: 9px;
      position: relative;
      padding-left: 0;
    }
    &.category,
    &.value {
      min-width: 175px;
      max-width: 175px;

      .select {
        min-width: 100%;
      }
    }

    &.statement {
      max-width: 120px;
      min-width: 120px;
      .MuiFormControl-root {
        .MuiInputBase-root {
          padding-right: 10px;
        }
      }
      select {
        text-align: center;
        border: none;
        border-radius: unset;
        border-bottom: 3px solid #878787;
        color: #878787;
        font-weight: bold;

        &.small-size {
          padding-right: 0;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.action {
  display: flex;
  justify-content: flex-end;

  .btnSaveNewDocument {
    width: 180px !important;
    height: 30px !important;
    border-radius: 6px;
    color: white;
  }

  .btnSaveNewDocumentDisabled {
    width: 180px !important;
    height: 30px !important;
    border-radius: 6px;
    background-color: #878787 !important;
    color: white;
  }
}
// }
