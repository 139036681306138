.MuiButton-root.ia-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  line-height: 24px;
  padding: 12px 24px;
  text-transform: none;
  height: 45px;
  max-height: 45px;
  min-width: 100px;
  border-radius: 2px;
  margin-right: 10px;
  letter-spacing: 0.2px;
  font-family: 'RocheSans-Regular';
  box-shadow: 0px 2px 4px 0px rgba(84, 79, 79, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.32);

  &:hover {
    cursor: pointer;
  }
  &.secondary-action-btn {
    background-color: #fff;
    color: #686d71;
    border: 1px solid #686d71;
    &:hover {
      background-color: #8a9197;
      border-color: #8a9197;
      color: #fff;
    }
    &:active {
      background-color: #686d71;
    }
  }

  &.main-action-btn {
    border: 1px solid #06c;
    background-color: #06c;
    color: #fff;
    &:hover {
      background-color: #0052a3;
      border-color: #0052a3;
    }
    &:active {
      background-color: #003d7a;
    }
    &:disabled {
      background-color: #eff0f2;
      border-color: #eff0f2;
      color: #adb5bd;
    }
  }
  &.back-btn {
    color: #06c;
    border: 1px solid #06c;
    background-color: #fff;
    &:hover {
      background-color: #0052a3;
      border-color: #0052a3;
      color: #fff;
    }
    &:active {
      background-color: #003d7a;
      color: #fff;
    }
    &:disabled {
      background-color: #eff0f2;
      border-color: #eff0f2;
      color: #adb5bd;
    }
  }
  &.error-action-btn {
    border: 1px solid #df0b0b;
    background: #df0b0b;
    color: #fff;
    &:hover {
      background-color: #B00F0F;
      border-color: #B00F0F;
    }
    &:active {
      background-color: #8B0707;
    }
  }
  svg {
    max-width: 1rem;
    margin-right: 8px;
  }
}
