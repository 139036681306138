.textarea {      
    label {
        color: #544F4F;
        font-family: 'RocheSans-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
    }  
    textarea {
        border-radius: 2px;
        border: 1px solid #C2BAB5;
        min-width: 96.5%;
        max-width: 96.5%;
        min-height: 75px;
        max-height: 175px;
        resize: vertical;     
        display: flex;
        padding: 12px 8px 8px 16px;
        align-items: flex-start;   
        font-size: 18px;
        color: #706B69;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        font-family: 'RocheSans-Light';
        margin-top: 4px;
        &:focus {
            outline: none;
        }      
    }
}