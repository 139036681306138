@import '../../assets/scss/variables';

.page-title {
  color: #21201F;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  flex-direction: column;
  span {
    font-weight: 400;
    font-size: 30px;
  }
  span:nth-child(1) {
    cursor: pointer;
    margin-right: 24px;
  }
  svg {
    margin-right: 5px;
  }
}
