@import '../../assets/scss/variables';

.tabs {
  position: relative;
  .actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    .manage-columns {
      position: relative;
      top: 0;
    }
    .create-new-doc {
      margin-right: 0;
      height: 36px;
      padding: 6px 16px;
    }
  }
}

.editable-content {
  min-height: 400px;
}

.links {
  .links-header {
    display: flex;
    justify-content: space-between;
  
    .add-link {
      display: flex;
      align-items: center;
      color: $mainBlue;
      text-align: center;
      font-family: 'RocheSans-Regular';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
      cursor: pointer;
      .plus-icon {
        margin-right: 8px;
        font-size: 20px;
      }
    }
  }
  .no-results {
    width: 35%;
    margin: 0 auto;
  }
}
