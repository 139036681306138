@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.dashboard {
  padding-bottom: 80px;
  .dashboard-title {
    margin-left: 26px;
    margin-top: 36px;

    div:nth-child(1) {
      color: #21201F;
      font-family: 'RocheSans-Regular';
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
    }

    div:nth-child(2) {
      color: #21201F;
      font-family: 'RocheSans-Regular';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.3px;
      margin-left: 1px;
    }
  }
  .table-title {
    color: #21201F;
    font-family: 'RocheSans-Medium';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-top: 32px;
  }
  .ia-table {
    margin: unset;
  }
  .alice-carousel__prev-btn {
    width: 35px;
    text-align: left;
    pointer-events: none;
    position: absolute;
    z-index: 2;
    left: -30px;
    img {
      pointer-events: auto;
      cursor: pointer;
    }
  }
  .alice-carousel__next-btn {
    width: 35px;
    text-align: right;
    pointer-events: none;
    position: absolute;
    z-index: 2;
    right: -30px;
    img {
      pointer-events: auto;
      cursor: pointer;
    }
  }
  .alice-carousel__wrapper {
    position: absolute;
    li {
      width: 100% !important;
    }
  }
  .alice-carousel__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    height: 75px;
  }
  .custom-prev-next {
    text-align: center;
    cursor: pointer;
    margin-top: 113px;
    height: 80px;

    @media all and (max-width: 1600px) {
      margin-top: 135px;
    }

    span {
      padding: 10px;
    }

    span:hover {
      color: $mainBlue;
    }
  }

  .tablabel-text {
    font-family: 'RocheSans-Regular';
    font-size: 16px;
    font-weight: 800;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
  }

  .tabpanel-text {
    font-family: 'RocheSans-Regular';
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #878787;
  }

  .tabpanel-pagination {
    float: right;
  }

  .divider {
    border: 1px solid #b0e2fa;
    width: 100%;
    clear: both;
  }

  .tab-button-group-view {
    padding: 6px;
    min-width: unset;
  }
  .tab-button-table-view {
    padding: 6px;
    min-width: unset;
    margin-left: 10px;
  }

  .table {
    margin-top: 20px;
  }
  .table-group {
    box-shadow: 0px 0px 5px 0px #b0e2fa;
    padding: 15px;
    margin: 5px;

    .page-title {
      border-radius: 10px 10px 0px 0px;
    }
    tr {
      border-bottom: none !important;
    }
    td {
      border-bottom: 1px solid #b0e2fa;
    }
  }

  .projects-card-list-footer {
    display: flex;
    justify-content: space-between;
    min-height: 65px;
  }
}

.dashboard-projects-slider {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;

  .swiper-slide {
    max-width: calc(100% / 3 - 32px);
    flex-basis: calc(100% / 3 - 32px);
    margin-right: 16px;
    margin-left: 16px;
    margin-bottom: 20px;
    height: auto;
    box-shadow: 0 0 5px 0 rgba(0, 101, 203, 0.37);
    border-radius: 10px;

    @media all and (max-width: 1400px) {
      max-width: calc(100% / 3 - 32px);
      flex-basis: calc(100% / 3 - 32px);
    }

    @media all and (max-width: 1200px) {
      max-width: calc(50% - 32px);
      flex-basis: calc(50% - 32px);
    }

    &:hover {
      border-left-color: #0880e6;
      cursor: pointer !important;

      .swiper-slide__icon:before {
        content: url('../../assets/images/folder_open.png');
      }
    }

    .project-status {
      text-align: right;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      padding: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      span:last-child {
        text-transform: uppercase;
      }

      &.in-progress {
        background: linear-gradient(90deg, #0066cc 0%, rgba(0, 102, 204, 0.39) 100%);
      }

      &.on-hold {
        background: linear-gradient(90deg, #f09712 0%, rgba(244, 162, 97, 0.54) 100%);
      }

      &.terminated {
        background: linear-gradient(90deg, #ff0000 0%, #ffb3b3 99.99%, #fffefe 100%);
      }

      &.completed {
        background: linear-gradient(90deg, #36b17e 0%, rgba(54, 177, 126, 0.54) 100%);
      }
    }

    .item-content {
      display: flex;
      // 41px status header height
      height: calc(100% - 41px);

      .text-information {
        // 63px = 15px padding left + 15px padding right + 33px icon width
        max-width: calc(100% - 63px);
        flex-basis: calc(100% - 63px);
        padding: 10px;

        .project-info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          max-width: 100%;

          > .d-flex {
            max-width: 100%;
            display: flex;
          }

          .project-title {
            text-transform: capitalize;
            color: #4f4f4f;
            font-size: 20px;
            font-weight: bold;
            line-height: 29px;
            max-width: calc(100% - 58px - 1rem);
            display: flex;
            flex-direction: column;
            margin: 10px;

            span {
              max-width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box !important;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              white-space: normal;
            }

            @media all and (max-width: 1440px) {
              font-size: 18px;
            }
          }

          .product-family {
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            color: $mainBlue;
            max-width: 100%;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .project-type {
          height: 70px;
          .label {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #878787;
          }

          .caption {
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;
            color: #4f4f4f;
            text-transform: capitalize;
            max-width: 100%;
            word-wrap: break-word;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .documents {
            display: flex;
            align-items: center;

            span {
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
              color: $mainBlue;
              margin-left: 5px;
            }
          }
        }

        .project-users {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 30px;

          .users {
            display: flex;
            align-items: center;

            div {
              width: 33px;
              height: 33px;
              background-color: #e9f2fb;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              color: #000;
              font-weight: bold;
              font-size: 12px;
              line-height: 14px;
              border: 2px solid #fff;
              margin-left: -5px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
              }

              @media all and (max-width: 1400px) {
                width: 30px;
                height: 30px;
                font-size: 11px;
              }

              &:first-child {
                margin-left: unset;
              }
            }
          }

          .date {
            .date-item {
              display: flex;
              justify-content: flex-end;

              &:first-child {
                margin-bottom: 5px;
              }

              span {
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;

                &:first-child {
                  color: #757575;
                }

                &:last-child {
                  color: #4f4f4f;
                  margin-left: 5px;
                }
              }
            }
          }

          .users-count {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: $mainBlue;
            margin-left: 5px;
          }
        }
      }

      .action-information {
        padding: 6px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 2px solid #b0e2fa;

        .setting {
          &:hover {
            img {
              filter: invert(26%) sepia(23%) saturate(7353%) hue-rotate(197deg) brightness(86%)
                contrast(102%);
            }
          }
        }

        .star-icon:not(.favorite):hover {
          svg path {
            fill: #f4a261;
            opacity: 0.8;
          }
        }

        svg {
          width: 33px;
          height: 33px;
        }

        .favorite {
          svg path {
            fill: #f4a261;
          }
        }
      }
    }

    .swiper-slide__icon {
      position: relative;
      width: 38px;
      height: 35px;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        content: url('../../assets/images/folder_close.png');
      }
    }

    .swiper-slide__last {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0 -15px calc(3rem + 35px) !important;

      .left-item {
        flex: 0 0 58%;
        max-width: 58%;
        padding-left: 15px;
      }

      .right-item {
        flex: 0 0 41%;
        max-width: 41%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .swiper-slide__function {
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
  }
}

.dashboard-tab-buttons {
  position: absolute;
  right: 0;
  top: 0;
}
