.confirmation-dialog {
    .dialog-header {
        align-items: flex-start;
    }
    .dialog-description {
        align-self: stretch;
        flex-grow: 0;
        font-family: 'RocheSans-Light';
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.2px;
        text-align: left;
        color: #000;
    }
}