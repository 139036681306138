.no-search-results {
  margin-top: 50px;

  .icon {
    width: 164px;
    height: 164px;
  }

  .title {
    color: #21201F;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'RocheSans-Regular';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
  }

  .sub-title {
    color: #706B69;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'RocheSans-Light';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
  }

  .video-tutorial-placeholder {
    margin-top: 62px;
  }
}