@import '../../assets/scss/variables';

.template-form {
    .select {
        width: 100% !important;
    }
    .input-with-label {
        width: 100% !important;
    }
  }

.template-exists-dialog {
    color: #000;
    font-family: 'RocheSans-Light';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
    a {
        color: $mainBlue;
        font-weight: 700;
        text-decoration: none;
    }
}

.publish-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    p {
        color: #000;
        font-family: 'RocheSans-Light';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-align: left;
    }
}

.add-member-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    color: #21201f;
    font-family: 'RocheSans-Light';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
}
