.ia-autocomplete {
  position: relative;
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiInputBase-root {
        border-radius: 2px;
        min-height: 48px;       
        padding: 0px 12px 0px 16px;
        .MuiAutocomplete-input {
          font-family: 'RocheSans-Light';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.2px;
        }
        input::placeholder {
          font-size: 18px;
          font-family: 'RocheSans-Light';
        }
        fieldset {
          border-width: 1px;
          border-color: #C2BAB5;
        }
        &:hover {
          fieldset {
            border-color: #C2BAB5;
          }
        }
      }
    }
  }
  .MuiAutocomplete-popper {
    .MuiAutocomplete-option {
      text-align: left !important;
    }
  }

  .divider {
    position: absolute;
    width: 4px;
    height: 100%;
    right: 30px;
    bottom: 0;
    background-color: #0065cb;
  }

  label {
    color: #706B69;
    font-family: 'RocheSans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    margin-bottom: 44px;            
  }

  .complexOptions {
    display: flex;
    flex-direction: column;
    .complexOptions-title {
      height: 24px;
      align-self: stretch;
      flex-grow: 0;
      font-family: 'RocheSans-Regular';
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.2px;
      text-align: left;
      color: #21201f;
    }
    .complexOptions-description {
      height: 96px;
      align-self: stretch;
      flex-grow: 0;
      font-family: 'RocheSans-Regular';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.2px;
      text-align: left;
      color: #21201f;
    }
  }
}
