.ia-input.MuiInputBase-root {
  input {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    color: #706b69;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
    height: 24px;
    font-family: 'RocheSans-Light';
  }
  input::placeholder {
    font-size: 18px;
    font-family: 'RocheSans-Light';
  }
  input:disabled {
    background-color: #FBFBFA;
  }
  fieldset {
    border-width: 1px;
    border-color: #c2bab5;
  }
  &:hover {
    fieldset {
      border-color: #c2bab5;
    }
  }
  border-radius: 2px;
  &.Mui-focused {
    fieldset {
      border-width: 1px;
      border-color: #c2bab5;
    }
  }
}
.input-with-label {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  label {
    color: #706b69;
    font-family: 'RocheSans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.2px;
  }
  .mandatory {
    color: #e51414;
    font-weight: bold;
    margin-left: 3px;
  }
}
