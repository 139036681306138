.video-container {
    position: relative;
  }
  
.color-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
}

video {
    display: block;
    width: 100%;
    border-radius: 10px;
}

.styled-arrow {
    position: absolute;
    top: calc(50% - 38px);
    left: calc(50% - 38px);
    cursor: pointer;
    svg {
        width: 76px;
        height: 76px;
    }
}
