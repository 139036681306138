@import '../../assets/scss/variables';
.scrollable-table {
  overflow-x: auto;
}
.ia-table {
  border-spacing: 0;
  .MuiTableCell-head {
    font-size: 14px;
    border-bottom: 2px solid $mainBlue;
    font-family: 'RocheSans-Regular';
    padding: 12px 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    background-color: #eae8e5;
  }
  .with-start-icon {
    .MuiTableCell-head:nth-child(1) {
      background-color: #f5f5f2;
    }
  }
  .MuiTableCell-head:nth-child(even) {
    background-color: #f5f5f2;
  }
  .MuiTableCell-head:last-child:nth-child(odd) {
    background-color: #f5f5f2;
  }
  .MuiTableCell-head:last-child:nth-child(even) {
    background-color: #eae8e5;
  }
  .MuiTableBody-root {
    .MuiTableCell-root {
      padding: 16px;
      font-size: 16px;
      font-family: 'RocheSans-Light';
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      &.favorite {
        svg {
          margin-right: 10px;
          vertical-align: middle;
        }
        .transfer-text {
          svg {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }
      }
    }
    .MuiTableRow-root {
      border-bottom: 1px solid #c2bab5;

      &:hover {
        .options {
          svg {
            opacity: 1;
          }
        }
      }
      .options {
        svg {
          font-size: 1.5em;
          color: rgba(0, 0, 0, 0.26);
          padding: 5px;
          opacity: 0;
        }
        display: flex;
        align-items: center;
      }
      .favorite-icon-option {
        font-size: 22px !important;
      }
    }
  }
  .options-cell {
    width: 5%;
  }
  .table-button {
    display: unset;
    padding: 6px 16px;
    border-radius: 2px;
    border: 1px solid #06c;
    color: #06c;
    text-align: center;
    font-family: 'RocheSans-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.2px;
    width: 110px;
  }
  .table-three-dots {
    vertical-align: middle;
    cursor: pointer;
  }
  .status-label {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 24px;
    background: #c7e1c4;
    color: #21201f;
    font-family: 'RocheSans-Regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
  }
  .table-refresh-icon {
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    float: right;
    margin-right: 16px;
    cursor: pointer;
  }
  .icon-group {
    display: flex;
  }
  .icon-group svg {
    width: 30px;
  }
}
.options-menu {
  .options-list {
    z-index: 999;
    a {
      text-decoration: none !important;
      color: #21201f !important;
    }

    .menu-item {
      color: #21201f !important;
      font-size: 18px;
      font-family: 'RocheSans-Light';
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.2px;
    }

    .MuiPopover-paper {
      width: 200px;
    }
  }
}

.row-link {
  text-align: left;
  color: $mainBlue;
  font-family: 'RocheSans-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-decoration: none;
}

table {
  overflow-x: auto;
  white-space: nowrap;
}

.table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 400px);
}

.error-icon {
  display: flex;
  justify-content: center;
}

.bottom-container {
  display: unset;
  &.with-tutorial {
    display: flex;
    justify-content: space-between;
    min-height: 65px;
  }
}

.members-count {
  color: $mainBlue;
}

.members-count:hover {
  font-weight: bold;
  font-size: 17px;
}

.download-button {
  margin: 0 10px;
}